import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, signal, WritableSignal } from "@angular/core";
import { TenantService } from "../tenant/tenant.service";
import { firstValueFrom, timer } from "rxjs";
import { StaticDataResponse } from "src/types/static-data-response";
import { Environment } from "src/types/environment";
import { ENV } from "src/providers/environment.provider";
import { LedgerCode } from "src/types/ledger-code";
import { Division } from "src/types/division";
import { CostCentre } from "src/types/cost-centre";
import { Country } from "src/types/country";
import { Currency } from "src/types/currency";
import { CustomerProduct } from "src/types/customer-product-summary";
import { FixedAssetsBook } from "src/types/modules/fixed-assets/fixed-assets-book";
import { FixedAssetsClass } from "src/types/modules/fixed-assets/fixed-assets-class";
import { GeneralStatus } from "src/types/general-status";
import { InvoiceDisputeReason } from "src/types/invoice-dispute-reason";
import { PaymentTermData } from "src/types/payment-term";
import { ProjectLedger } from "src/types/project-ledger";
import { ProjectType } from "src/types/project-type";
import { StandardDay } from "src/types/standard-day";
import { VatCode } from "src/types/vat-code";
import { FixedAssetCondition } from "src/types/fixed-asset/fixed-asset-condition";
import { FixedAssetDisposalType } from "src/types/fixed-asset/fixed-asset-disposal-type";
import { FixedAssetDisposalReason } from "src/types/fixed-asset/fixed-asset-disposal-reason";

export interface RefreshOptions {
  /**
   * Whether the service should retrieve results from it's cache or if it should
   * be forced to update via a HTTP request.
   */
  force?: boolean;
}

const caches = {
  costCentres: signal<CostCentre[]>([]), // Done
  countries: signal<Country[]>([]),
  currencies: signal<Currency[]>([]),
  customerProducts: signal<CustomerProduct[]>([]),
  divisions: signal<Division[]>([]),
  fixedAssetBooks: signal<FixedAssetsBook[]>([]),
  fixedAssetClasses: signal<FixedAssetsClass[]>([]),
  fixedAssetConditions: signal<FixedAssetCondition[]>([]),
  fixedAssetDisposalReasons: signal<FixedAssetDisposalReason[]>([]),
  fixedAssetDisposalTypes: signal<FixedAssetDisposalType[]>([]),
  generalStatuses: signal<GeneralStatus[]>([]),
  invoiceDisputeReasons: signal<InvoiceDisputeReason[]>([]),
  ledgerCodes: signal<LedgerCode[]>([]),
  paymentTerms: signal<PaymentTermData[]>([]),
  projectLedgers: signal<ProjectLedger[]>([]),
  projectTypes: signal<ProjectType[]>([]),
  standardDays: signal<StandardDay[]>([]),
  'vat-codes': signal<VatCode[]>([]),
} as const;

type CacheType = keyof typeof caches;

type CacheValue<TType extends CacheType> = typeof caches[TType] extends WritableSignal<(infer U)[]> ? U : never

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {
  constructor(
    @Inject(ENV) private readonly environment: Environment,
    private readonly tenantService: TenantService,
    private readonly http: HttpClient
  ) { }

  public async refreshStaticData(): Promise<void> {
    const apiUrl = await this.tenantService.getApiUrl();
    const url = apiUrl + 'Objects?Source=Config';
    const headers = await this.tenantService.getAuthorizationHeaders();
    const response = await firstValueFrom(this.http.get<StaticDataResponse>(url, { headers: headers }));

    caches.costCentres.set(response.costCentres);
    caches.countries.set(response.countries);
    caches.currencies.set(response.currencies);
    caches.customerProducts.set(response.customerProducts);
    caches.divisions.set(response.divisions);
    caches.fixedAssetBooks.set(response.fixedAssetsBooks);
    caches.fixedAssetClasses.set(response.fixedAssetsClasses);
    caches.fixedAssetConditions.set(response.fixedAssetConditions);
    caches.fixedAssetDisposalReasons.set(response.fixedAssetDisposalReasons);
    caches.fixedAssetDisposalTypes.set(response.fixedAssetDisposalTypes);
    caches.generalStatuses.set(response.generalStatuses);
    caches.invoiceDisputeReasons.set(response.invoiceDisputeReasons);
    caches.ledgerCodes.set(response.ledgerCodes);
    caches.paymentTerms.set(response.paymentTerms);
    caches.projectLedgers.set(response.projectLedgers);
    caches.projectTypes.set(response.projectTypes);
    caches.standardDays.set(response.standardDays);
    caches['vat-codes'].set(response.vatCodes);


    timer(this.environment.staticDataExpireDurationInMS).subscribe(() => {
      console.log('StaticData has expired. Refreshing data...');
      this.refreshStaticData(); // Don't await
    });
  }

  public getAllFromCache<T extends CacheType>(key: T): CacheValue<T>[] {
    return (caches[key] as WritableSignal<CacheValue<T>[]>)();
  }

  public async getFromCache<T extends CacheType, TKey extends keyof CacheValue<T>>(
    key: T,
    idProp: TKey,
    id: CacheValue<T>[TKey],
    /**
     * If the cache cannot find the desired item, it will use this function to try and retrieve it as a fallback
     */
    fallback?: () => Promise<CacheValue<T>>
  ): Promise<CacheValue<T>> {
    const cache = caches[key] as WritableSignal<CacheValue<T>[]>;
    const values = cache();
    const value = values.find(x => x[idProp] === id);
    if (value) { return value; }
    if (!fallback) { throw new Error(`Could not find ${key} with id ${id} and no fallback was provided`); }

    const fallbackResult = await fallback();

    // If found add missing value to cache and update.
    cache.update((values) => {
      values.push(fallbackResult);
      return values;
    });

    return fallbackResult;
  }

  public setCache<T extends CacheType>(key: T, values: CacheValue<T>[]): void {
    const cache = caches[key] as WritableSignal<CacheValue<T>[]>;
    cache.set(values);
  }

  public updateCache<T extends CacheType, TKey extends keyof CacheValue<T>>(key: T, idProp: TKey, value: CacheValue<T>): void {
    const cache = caches[key] as WritableSignal<CacheValue<T>[]>;
    cache.update((values) => {
      const index = values.findIndex(x => x[idProp] === value[idProp])
      if (index >= 0) { values[index] = value; }
      else { values.push(value); }

      return values;
    });
  }

  public removeFromCache<T extends CacheType, TKey extends keyof CacheValue<T>>(
    key: T,
    idProp: TKey,
    id: CacheValue<T>[TKey]): void {
    const cache = caches[key] as WritableSignal<CacheValue<T>[]>;
    cache.update(values => (values.filter(x => x[idProp] !== id)));
  }
}